import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import { TextInput } from "grommet";
import { Search } from "grommet-icons";

import { actions } from "../ducks";
import "./styles/AllTemplatesList.css";

class AllTemplatesList extends Component {
  state = { isFetching: false, list: [] };

  searchTemplates = (e) => this.props.fetchTemplates(e.target.value);

  render() {
    return (
      <div className="AllTemplatesList">
        <header>All templates</header>
        <div className="search-bar">
          <Search color="brand" />
          <TextInput
            type="search"
            placeholder="Search templates..."
            onChange={this.searchTemplates}
            plain
          />
        </div>
        {this.props.isFetching ? (
          <ReactLoading
            className="loader"
            type="spin"
            color="purple"
            height="5%"
            width="5%"
          />
        ) : (
          <div className="results">
            {this.props.list.map(({ id, name, orgName }) =>
              <Link to={`/template/${id}`} key={id}>
                {name}
                <p className="org-name">{orgName}</p>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }
}

AllTemplatesList.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
};

const mapStateToProps = ({ isFetchingTemplates, templates }) => ({
  isFetching: isFetchingTemplates,
  list: templates,
});

const actionsToMap = {
  fetchTemplates: actions.fetchTemplates.request,
};

export default connect(mapStateToProps, actionsToMap)(AllTemplatesList);
