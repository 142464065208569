import { call } from "redux-saga/effects";
import ls from "lscache";
import { authClient } from "./auth";

const getBase = (optionalEnv) => {
  const env = optionalEnv || ls.get("env");
  switch (env) {
    case "prod":
      return "https://api.abstractcre.com";
    case "staging":
      return "https://staging-api.abstractcre.com";
    case "dev":
      return "http://localhost:5000";
    default:
      return "";
  }
}

const isDevEnv = process.env.NODE_ENV === "development";

function* callApi(endpoint, config = {}) {
  const token = isDevEnv ? ls.get("token") : yield authClient.getTokenSilently();
  const c = {
    ...config,
    mode: "cors",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const res = yield call(fetch, endpoint, c);
  if (!res.ok) {
    throw yield res.json();
  }
  return yield res.json();
}

// POST /dev/users/login
export const login = (email) =>
  callApi(`${getBase()}/dev/users/login`, {
    method: "POST",
    body: JSON.stringify({ email }),
  });

// GET /users/me
export const getSelf = () =>
  callApi(`${getBase()}/users/me`, { method: "GET" });

/* /link_prediction base */
const linkPrediction = (method, route, body) => {
  const config = { method };
  if (body) config.body = JSON.stringify(body);
  return callApi(`${getBase()}/link_prediction/${route}`, config);
}

/* GET /link_prediction/unassociated_templates
 * Body should be [{"id": 12, "name": "Leases", "orgName": "Hooli"}]
 */
export const getUnassociatedTemplates = () =>
  linkPrediction("GET", "unassociated_templates");

/* GET /link_prediction/template/:id
 */
export const getTemplate = (id) =>
  linkPrediction("GET", `templates/${id}`);

/* GET /link_prediction/all_templates
 */
export const getTemplates = (query) =>
  linkPrediction("GET", `all_templates?name_contains=${query}`);

/* GET /link_prediction/generic_template
 */
export const createGenericTemplate = (name) =>
  linkPrediction("POST", "generic_template", {name});

/* PUT /link_prediction/generic_template/:id
 */
export const saveGenericTemplate = (id, body) =>
  linkPrediction("PUT", `generic_template/${id}`, body);

/* GET /link_prediction/generic_templates
 */
export const getGenericTemplates = () =>
  linkPrediction("GET", "generic_templates");

/* POST /link_prediction/associate_generic_template/<template>/<generic_template>
 */
export const associateTemplates = (template_id, gen_template_id, mapping) =>
  linkPrediction(
    "POST",
    `associate_generic_template/${template_id}/${gen_template_id}`,
    { mapping }
  );
