import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { actions } from "../ducks";

import UnassociatedTemplatesList from "./UnassociatedTemplatesList";
import AllTemplatesList from "./AllTemplatesList";

import "./styles/TemplatesDashboard.css";

class TemplatesDashboard extends Component {
  componentDidMount() {
    this.props.fetchUnassociatedTemplates();
  }

  render() {
    const { isFetching, unassociated } = this.props;

    return (
      <div className="TemplatesDashboard">
        {isFetching ? (
          <ReactLoading className="loader" type="spin" color="purple" height="5%" width="5%" />
        ) : (
            <Fragment>
              <h2>There are {unassociated.length} unassociated templates</h2>
              <UnassociatedTemplatesList data={unassociated} />
              <AllTemplatesList />
            </Fragment>
        )}
      </div>
    );
  }
}

TemplatesDashboard.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  unassociated: PropTypes.array.isRequired,
};

const mapStateToProps = ({
  isFetchingUnassociatedTemplates,
  unassociatedTemplates,
}) => ({
  isFetching: isFetchingUnassociatedTemplates,
  unassociated: unassociatedTemplates,
});

const actionsToMap = {
  fetchUnassociatedTemplates: actions.fetchUnassociatedTemplates.request,
};

export default connect(mapStateToProps, actionsToMap)(TemplatesDashboard);
