import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextInput, Button } from "grommet";
import { actions } from "../ducks";
import "./styles/CreateGenericTemplate.css";

class CreateGenericTemplate extends Component {
  state = { name: "" };

  create = () => this.props.create(this.state.name);

  render() {
    return (
      <div className="CreateGenericTemplate">
        <TextInput
          placeholder="Name"
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
        />
        <Button
          label="Create"
          onClick={this.create}
          primary
        />
      </div>
    );
  }
}

CreateGenericTemplate.propTypes = {
  create: PropTypes.func.isRequired,
};

const actionsToMap = {
  create: actions.createGenericTemplate.request,
};

export default connect(null, actionsToMap)(CreateGenericTemplate);
