import React, { Component } from "react";
import { Link } from "react-router-dom";

class UnassociatedTemplatesList extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="UnassociatedTemplatesList">
        <header>Unassociated Templates</header>
        {data.map(({name, id, orgName}) => (
          <Link to={`/template/${id}`} key={id}>
            {name}
            <span className="org-name">Org: {orgName}</span>
          </Link>
        ))}
      </div>
    );
  }
}

export default UnassociatedTemplatesList;
