import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select, TextInput, Button } from "grommet";
import { Close, Trash } from "grommet-icons";

import { actions } from "../ducks";
import "./styles/GenericTemplateFieldsList.css";

class GenericTemplateFieldsList extends Component {
  state = {
    name: "",
    toAdd: [],
  };

  componentDidMount(props) {
    this.setName();
  }

  componentDidUpdate(prevProps) {
    const { curGenericTemplate } = this.props;
    if (prevProps.curGenericTemplate !== curGenericTemplate) {
      this.setName();
    }
  }

  setName = () => {
    const { curGenericTemplate, genericTemplates } = this.props;
    const cur = genericTemplates[curGenericTemplate] || {};
    const { name = "" } = cur;
    this.setState({ name });
  }

  addField = () => {
    this.setState({ toAdd: [
      ...this.state.toAdd,
      { name: "", type: "text" },
    ]});
  }

  updateFieldToAdd = (i, name, type) => {
    const toAdd = [ ...this.state.toAdd ];
    toAdd[i] = { name, type };
    this.setState({ toAdd });
  }

  removeFieldToAdd = (i) => {
    const { toAdd } = this.state;
    this.setState({
      toAdd: [
        ...toAdd.slice(0, i),
        ...toAdd.slice(i+1)
      ]
    });
  }

  handleSave = () => {
    const { name, toAdd } = this.state;
    const { template, genericTemplates, curGenericTemplate, saveGenericTemplate } = this.props;
    const cur = genericTemplates[curGenericTemplate] || {};
    const { id, fields = [] } = cur;

    const payload = {
      name,
      fields: [
        ...fields.map(({ count, ...rest }) => rest),
        ...toAdd.filter((t) => t.name !== ""),
      ],
    };
    saveGenericTemplate(id, payload, template.id);
    this.setState({ toAdd: [], name });
  }

  render() {
    const { removeGenericTemplateField, genericTemplates, curGenericTemplate } = this.props;
    const cur = genericTemplates[curGenericTemplate] || {};
    const { fields = [] } = cur;

    const typeOptions = {
      "text": "Text",
      "number": "Number",
      "dropdown": "Dropdown",
      "date": "Date",
      "currency": "Currency",
      "long_text": "Long Text",
      };

    const fieldsByType = fields.reduce((res, cur) => {
      (res[cur["type"]] = res[cur["type"]] || []).push(cur);
      return res;
    }, {});

    return (
      <div className="GenericTemplateFieldsList">
        {!Object.keys(cur).length ?
            <h2 className="none">
              Select a generic template on the left
            </h2> :
            <div className="generic-template-wrapper">
              <div className="template-info">
                <TextInput
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <div className="save">
                  <Button
                    label="Save"
                    type="button"
                    onClick={this.handleSave}
                    primary
                  />
                </div>
              </div>
              {Object.entries(fieldsByType).map(([type, fields]) => (
                <div className="fields-wrapper" key={type}>
                  <header className="type">{typeOptions[type]}</header>
                  {fields.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  }).map(({id, name, type, count}) => (
                    <div className="field-wrapper" key={id}>
                      <p className="name">{name} ({count})</p>
                      <Trash
                        onClick={() => removeGenericTemplateField(id)}
                        color="tomato"
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div className="fields-to-add">
                {this.state.toAdd.map(({ name, type }, i) =>
                  <div className="field-to-add-wrapper" key={i}>
                    <TextInput
                      placeholder="Name"
                      value={name}
                      onChange={(e) => this.updateFieldToAdd(i, e.target.value, type)}
                    />
                    <Select
                      label="Type"
                      options={Object.keys(typeOptions)}
                      value={type}
                      onChange={({value}) => this.updateFieldToAdd(i, name, value)}
                    />
                    <Close
                      onClick={() => this.removeFieldToAdd(i)}
                      color="tomato"
                    />
                  </div>
                )}
                <Button
                  label="Add Field"
                  onClick={this.addField}
                  secondary
                />
                <p className="explain">(Press "Save" up top to persist the fields)</p>
              </div>
            </div>
        }
      </div>
    );
  }
}

GenericTemplateFieldsList.propTypes = {
  curGenericTemplate: PropTypes.any,
  saveGenericTemplate: PropTypes.func.isRequired,
  genericTemplates: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  genericTemplates,
  curGenericTemplate,
  template,
}) => ({ genericTemplates, curGenericTemplate, template });

const actionsToMap = {
  saveGenericTemplate: actions.saveGenericTemplate.request,
  removeGenericTemplateField: actions.removeGenericTemplateField,
};

export default connect(mapStateToProps, actionsToMap)(GenericTemplateFieldsList);
