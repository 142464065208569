import ls from "lscache";

export const types = {
  // Dev purposes only
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  SIGNOUT: "SIGNOUT",

  AUTH_CLIENT_INITIALIZED: "AUTH_CLIENT_INITIALIZED",

  GET_USER_DATA: "GET_USER_DATA",
  GET_USER_DATA_SUCCESS: "GET_USER_DATA_SUCCESS",

  FETCH_UNASSOCIATED_TEMPLATES: "FETCH_UNASSOCIATED",
  FETCH_UNASSOCIATED_TEMPLATES_SUCCESS: "FETCH_UNASSOCIATED_SUCCESS",
  FETCH_UNASSOCIATED_TEMPLATES_FAILURE: "FETCH_UNASSOCIATED_FAILURE",

  FETCH_TEMPLATE: "FETCH_TEMPLATE",
  FETCH_TEMPLATE_SUCCESS: "FETCH_TEMPLATE_SUCCESS",
  FETCH_TEMPLATE_FAILURE: "FETCH_TEMPLATE_FAILURE",

  FETCH_TEMPLATES: "FETCH_TEMPLATES",
  FETCH_TEMPLATES_SUCCESS: "FETCH_TEMPLATES_SUCCESS",
  FETCH_TEMPLATES_FAILURE: "FETCH_TEMPLATES_FAILURE",

  FETCH_GENERIC_TEMPLATES: "FETCH_GENERIC_TEMPLATES",
  FETCH_GENERIC_TEMPLATES_SUCCESS: "FETCH_GENERIC_TEMPLATES_SUCCESS",
  FETCH_GENERIC_TEMPLATES_FAILURE: "FETCH_GENERIC_TEMPLATES_FAILURE",

  ASSOCIATE_TEMPLATES: "ASSOCIATE_TEMPLATES",
  ASSOCIATE_TEMPLATES_SUCCESS: "ASSOCIATE_TEMPLATES_SUCCESS",
  ASSOCIATE_TEMPLATES_FAILURE: "ASSOCIATE_TEMPLATES_FAILURE",

  EDIT_GENERIC_TEMPLATE: "EDIT_GENERIC_TEMPLATE",
  EDIT_GENERIC_TEMPLATE_SUCCESS: "EDIT_GENERIC_TEMPLATE_SUCCESS",
  EDIT_GENERIC_TEMPLATE_FAILURE: "EDIT_GENERIC_TEMPLATE_FAILURE",

  CREATE_GENERIC_TEMPLATE: "CREATE_GENERIC_TEMPLATE",
  CREATE_GENERIC_TEMPLATE_SUCCESS: "CREATE_GENERIC_TEMPLATE_SUCCESS",
  CREATE_GENERIC_TEMPLATE_FAILURE: "CREATE_GENERIC_TEMPLATE_FAILURE",

  SELECT_GENERIC_TEMPLATE: "SELECT_GENERIC_TEMPLATE",

  REMOVE_GENERIC_TEMPLATE_FIELD: "REMOVE_GENERIC_TEMPLATE_FIELD",
};

export const actions = {
  markAuthClientInitialized: () => ({ type: types.AUTH_CLIENT_INITIALIZED }),
  signout: () => ({ type: types.SIGNOUT }),

  login: {
    request: (env, email) => ({ type: types.LOGIN, env, email }),
    success: (id) => ({ type: types.LOGIN_SUCCESS, id }),
    failure: (err) => ({ type: types.LOGIN_FAILURE, err }),
  },

  getUserData: {
    request: () => ({ type: types.GET_USER_DATA }),
    success: (name, email) => ({ type: types.GET_USER_DATA_SUCCESS, name, email }),
  },

  fetchUnassociatedTemplates: {
    request: () => ({ type: types.FETCH_UNASSOCIATED_TEMPLATES }),
    success: (templates) => ({ type: types.FETCH_UNASSOCIATED_TEMPLATES_SUCCESS, templates }),
    failure: (err) => ({ type: types.FETCH_UNASSOCIATED_TEMPLATES_FAILURE, err }),
  },

  fetchTemplate: {
    request: (id) => ({ type: types.FETCH_TEMPLATE, id }),
    success: (template) => ({ type: types.FETCH_TEMPLATE_SUCCESS, template }),
    failure: (err) => ({ type: types.FETCH_TEMPLATE_FAILURE, err }),
  },

  fetchTemplates: {
    request: (nameFilter) => ({ type: types.FETCH_TEMPLATES, nameFilter }),
    success: (templates) => ({ type: types.FETCH_TEMPLATES_SUCCESS, templates }),
    failure: (err) => ({ type: types.FETCH_TEMPLATES_FAILURE, err }),
  },

  fetchGenerics: {
    request: () => ({ type: types.FETCH_GENERIC_TEMPLATES }),
    success: (genericTemplates) => ({ type: types.FETCH_GENERIC_TEMPLATES_SUCCESS, genericTemplates }),
    failure: (err) => ({ type: types.FETCH_GENERIC_TEMPLATES_FAILURE, err }),
  },

  associateTemplates: {
    request: (templateId, genericTemplateId, mapping) => ({
      type: types.ASSOCIATE_TEMPLATES, templateId, genericTemplateId, mapping
    }),
    success: () => ({ type: types.ASSOCIATE_TEMPLATES_SUCCESS, success: "Mapping updated!" }),
    failure: (err) => ({ type: types.ASSOCIATE_TEMPLATES_FAILURE, err }),
  },

  saveGenericTemplate: {
    request: (genericTemplateId, payload, templateId) => ({
      type: types.EDIT_GENERIC_TEMPLATE,
      genericTemplateId,
      payload,
      templateId
    }),
    success: (template) => ({
      type: types.EDIT_GENERIC_TEMPLATE_SUCCESS, template, success: "Generic template saved!",
    }),
    failure: (err) => ({ type: types.EDIT_GENERIC_TEMPLATE_FAILURE, err }),
  },

  createGenericTemplate: {
    request: (name) => ({ type: types.CREATE_GENERIC_TEMPLATE, name }),
    success: (template) => ({ type: types.CREATE_GENERIC_TEMPLATE_SUCCESS, template, success: "Generic template created!" }),
    failure: (err) => ({ type: types.CREATE_GENERIC_TEMPLATE_FAILURE, err }),
  },

  selectGenericTemplate: (id) => ({ type: types.SELECT_GENERIC_TEMPLATE, id }),

  removeGenericTemplateField: (id) => ({ type: types.REMOVE_GENERIC_TEMPLATE_FIELD, id }),
};


const defaultState = {
  id: 0,
  name: "",
  email: "",

  unassociatedTemplates: [],
  templates: [],
  genericTemplates: {},
  template: {},

  curGenericTemplate: null,

  isAuthClientInitialized: process.env.NODE_ENV === "development",
  isLoggedIn: !!ls.get("token"),
  isFetchingUnassociatedTemplates: false,
  isFetchingTemplates: false,
  isFetchingGenericTemplates: false,
  isFetchingTemplate: false,
  isGettingUserData: false, // TODO
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true };
    case types.SIGNOUT:
      return { ...state, isLoggedIn: false };

    case types.AUTH_CLIENT_INITIALIZED:
      return { ...state, isAuthClientInitialized: true };

    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        isGettingUserData: false,
        name: action.name,
        email: action.email,
      };

    case types.FETCH_UNASSOCIATED_TEMPLATES:
      return {
        ...state,
        unassociatedTemplates: defaultState.unassociatedTemplates,
        isFetchingUnassociatedTemplates: true,
      };
    case types.FETCH_UNASSOCIATED_TEMPLATES_SUCCESS:
      return {
        ...state,
        unassociatedTemplates: action.templates,
        isFetchingUnassociatedTemplates: false,
      };
    case types.FETCH_UNASSOCIATED_TEMPLATES_FAILURE:
      return {
        ...state,
        unassociatedTemplates: defaultState.unassociatedTemplates,
        isFetchingUnassociatedTemplates: false,
      };

    case types.FETCH_TEMPLATE:
      return { ...state, isFetchingTemplate: true, template: defaultState.template };
    case types.FETCH_TEMPLATE_SUCCESS:
      return { ...state, isFetchingTemplate: false, template: action.template };
    case types.FETCH_TEMPLATE_FAILURE:
      return { ...state, isFetchingTemplate: false, template: defaultState.template };

    case types.FETCH_TEMPLATES:
      return { ...state, isFetchingTemplates: true, templates: defaultState.templates };
    case types.FETCH_TEMPLATES_SUCCESS:
      return { ...state, isFetchingTemplates: false, templates: action.templates };
    case types.FETCH_TEMPLATES_FAILURE:
      return { ...state, isFetchingTemplates: false, templates: defaultState.templates };

    case types.FETCH_GENERIC_TEMPLATES:
      return { ...state, isFetchingGenericTemplates: true, genericTemplates: defaultState.genericTemplates };
    case types.FETCH_GENERIC_TEMPLATES_SUCCESS:
      return { ...state, isFetchingGenericTemplates: false, genericTemplates: action.genericTemplates };
    case types.FETCH_GENERIC_TEMPLATES_FAILURE:
      return { ...state, isFetchingGenericTemplates: false, genericTemplates: defaultState.genericTemplates };

    case types.EDIT_GENERIC_TEMPLATE_SUCCESS:
    case types.CREATE_GENERIC_TEMPLATE_SUCCESS:
      return {
        ...state,
        genericTemplates: {
          ...state.genericTemplates,
          [action.template.id]: action.template,
        },
      };

    case types.REMOVE_GENERIC_TEMPLATE_FIELD:
      return {
        ...state,
        genericTemplates: {
          ...state.genericTemplates,
          [state.curGenericTemplate]: {
            ...state.genericTemplates[state.curGenericTemplate],
            fields: state.genericTemplates[state.curGenericTemplate].fields.filter(
              (f) => f.id !== action.id
            ),
          }
        },
      };

    case types.SELECT_GENERIC_TEMPLATE:
      return { ...state, curGenericTemplate: action.id };

    default:
      return state;
  }
};

export default reducer;
