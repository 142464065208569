/**
 * This reducer gets applied to all actions, and determines whether they should send alerts
 *
 * If an action return value contains the key `err`, it will flash an error alert with the message value action.err.
 * If an action return value contains the key `success`, it will flash an error alert with the message value action.success.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Alert, Validate } from "grommet-icons";

const POP = "alerts/POP";
export const pop = () => ({ type: POP });
export function reducer(state = [], action) {
  if (action.err) {
    return [...state, { type: "err", msg: action.err }];
  }

  if (action.success) {
    return [...state, { type: "success", msg: action.success }];
  }

  switch (action.type) {
    case POP:
      return state.slice(1);
    default:
      return state;
  }
}

class AlertsComponent extends Component {
  componentDidUpdate(prevProps) {
    const { alerts, pop } = this.props;
    const len = alerts.length;
    if (len && len > prevProps.alerts.length) {
      setTimeout(() => pop(), 3000);
    }
  }

  wrapperStyles = {
    position: "absolute",
    zIndex: 1000,
    bottom: 15,
    right: 30,
  };

  alertStyles = {
    display: "flex",
    alignItems: "center",
    border: "2px solid #CCC",
    fontWeight: "bold",
    padding: 15,
    marginBottom: 15,
  };

  iconStyles = {
    paddingRight: 5,
  };

  render() {
    return (
      <TransitionGroup style={this.wrapperStyles}>
        {this.props.alerts
          .filter(({ msg }) => msg !== "")
          .map(({ type, msg }, i) => (
            <CSSTransition key={i} timeout={500} classNames="fade">
              <div style={this.alertStyles}>
                <div style={this.iconStyles}>
                  {type === "err" && <Alert color="tomato" />}
                  {type === "success" && <Validate color="green" />}
                </div>
                <div className="msg">
                  <span>{msg}</span>
                </div>
              </div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  }
}

AlertsComponent.propTypes = {
  alerts: PropTypes.array.isRequired,
  pop: PropTypes.func.isRequired,
};

const mapStateToProps = ({ alerts }) => ({ alerts });
const actionsToMap = { pop };

export default connect(mapStateToProps, actionsToMap)(AlertsComponent);
