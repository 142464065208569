import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LinkNext } from "grommet-icons";
import Select from 'react-select';
import { Button } from "grommet";

import { actions } from "../ducks";
import "./styles/TemplateMappingPanel.css";

class TemplateMappingPanel extends Component {
  state = { fieldMappings: Object.keys(this.props.template).length ?
    this.props.template.mappings.reduce((res, cur) => ({ ...res, [cur.template_field]: cur.generic_field }), {}) :
    {}
  };

  componentDidUpdate(prevProps) {
    const {
      curGenericTemplate: prevGeneric,
      template: { mappings: prevMappings },
    } = prevProps;
    const {
      curGenericTemplate,
      template: { generic_template_id, mappings = [] },
    } = this.props;
    if (prevGeneric !== curGenericTemplate || prevMappings.length !== mappings.length) {
      const fieldMappings = (curGenericTemplate === generic_template_id) ? (
        mappings.reduce((res, cur) => ({ ...res, [cur.template_field]: cur.generic_field }), {})
      ) : {};
      this.setState({ fieldMappings });
    }
  }

  handleSave = () => {
    const { curGenericTemplate, template: { id = 0 }, associateTemplates } = this.props;
    const mappings = Object.keys(this.state.fieldMappings).map((k) => ({
      template_field: k,
      generic_field: this.state.fieldMappings[k],
    }));
    associateTemplates(id, curGenericTemplate, mappings);
  }

  render() {
    const {
      template = {},
      curGenericTemplate,
      genericTemplates = {},
      selectGenericTemplate,
    } = this.props;

    const { id = 0, name = "", sections = [], orgName = "" } = template;
    const { fields: genericFields = [] } = genericTemplates[curGenericTemplate] || {};

    const genericTemplateOptions = [
      { value: "new", label: "New Generic Template" },
      ...Object.keys(genericTemplates).map((id) => ({
        value: genericTemplates[id].id,
        label: genericTemplates[id].name,
      })),
    ];

    const fieldOptions = genericFields.map(({ name, id, type }) => ({
      value: id,
      label: name,
    }));

    return (
      <div className="TemplateMappingPanel">
        <div className="template-info-wrapper">
          <div className="template-info">
            <h2>Name: {name}</h2>
            <h2>Org: {orgName}</h2>
            <h2>ID: {id}</h2>
          </div>
          <div className="generic-and-save">
            <div className="save">
              <Button
                type="button"
                label="Save"
                onClick={this.handleSave}
                primary
              />
            </div>
            <label>Generic Template</label>
            <Select
              options={genericTemplateOptions}
              onChange={({value}) => selectGenericTemplate(value)}
              value={genericTemplateOptions.find(({value}) => value === curGenericTemplate)}
            />
          </div>
        </div>
        {sections.map(({id: sectionId, name: sectionName, fields}) => (
          <div className="section-wrapper" key={sectionId}>
            <p className="section-name">{sectionName}</p>
            {fields.map(({id: fieldId, name: fieldName, type }) => (
              <div className="field-wrapper" key={fieldId}>
                <div className="field-info">
                  <p className="field-name">{fieldName}</p>
                  <p className="field-type"> Type: {type}</p>
                </div>
                <div className="icon">
                  <LinkNext />
                </div>
                <Select
                  options={
                    genericFields
                      .filter(
                        ({type: gType}) =>
                          (gType === type) ||
                          (type === "text" && gType === "long_text")
                      )
                      .map(({ name, id, type }) => ({
                        value: id,
                        label: name,
                      }))
                  }
                  value={
                    fieldOptions.find((t) => (
                      this.state.fieldMappings[fieldId] &&
                      this.state.fieldMappings[fieldId] === t.value
                    )) || null
                  }
                  onChange={(option) => this.setState((state) => ({
                    fieldMappings: option === null
                      ? Object.keys(state.fieldMappings)
                        .filter((f) => f !== fieldId)
                        .reduce((res, cur) => ({
                          ...res, [cur]: state.fieldMappings[cur]
                        }), {})
                      : {...state.fieldMappings, [fieldId]: option.value},
                  }))}
                  isClearable
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

TemplateMappingPanel.propTypes = {
  selectGenericTemplate: PropTypes.func.isRequired,
  curGenericTemplate: PropTypes.any,
  template: PropTypes.object.isRequired,
  genericTemplates: PropTypes.object.isRequired,
  associateTemplates: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  template,
  genericTemplates,
  curGenericTemplate,
}) => ({ template, genericTemplates, curGenericTemplate });

const actionsToMap = {
  associateTemplates: actions.associateTemplates.request,
  selectGenericTemplate: actions.selectGenericTemplate,
};

export default connect(mapStateToProps, actionsToMap)(TemplateMappingPanel);
