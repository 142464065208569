import React from 'react';
import { Grommet } from "grommet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import AlertComponent from "../alerts";
import Authenticated from "../auth/Authenticated";
import Callback from "../auth/Callback";
import Login from "../auth/Login";
import "./App.css";

export const history = createBrowserHistory();

const App = ({ isAuthClientInitialized }) => {
  const theme = {
    global: {
      font: {
        family: 'Roboto',
        size: '14px',
        height: '20px',
      },
    },
  };

  const routes = (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/callback" component={Callback} />
      <Route path="/" component={Authenticated} />
    </Switch>
  );

  return (
    <Grommet theme={theme}>
      <Router history={history}>
        {isAuthClientInitialized ? routes : null}
      </Router>
      <AlertComponent />
    </Grommet>
  );
};

const mapStateToProps = ({ isAuthClientInitialized }) => ({
  isAuthClientInitialized,
});

export default connect(mapStateToProps)(App);
