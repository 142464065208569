import React, { useEffect, useState } from "react";
import { authClient } from "./index";
import { Redirect } from "react-router";

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const LoginCallback = () => {
  const [redirectTo, setRedirectTo] = useState("");

  useEffect(() => {
    const params = window.location.search;
    const hasAuthParams =
      (CODE_RE.test(params) && STATE_RE.test(params)) || ERROR_RE.test(params);

    if (hasAuthParams) {
      authClient.handleRedirectCallback().then(() => {
        setRedirectTo("/");
      });
    } else {
      setRedirectTo("/");
    }
  }, []);

  if (redirectTo) {
    return <Redirect to={{ pathname: redirectTo }} />;
  }

  const pStyle = { margin: 0, padding: 15 };
  return <p style={pStyle}>Redirecting...</p>;
};

export default LoginCallback;
