import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import 'whatwg-fetch';

import { authClient } from "./auth";
import { store } from "./store";
import { actions } from "./ducks";

import App from './app/App';
import './index.css';

if (authClient !== null) {
  authClient.checkSession().then(() => {
    store.dispatch(actions.markAuthClientInitialized());
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
