import { call, put, takeLatest, all } from "redux-saga/effects";
import ls from "lscache";

import { types, actions } from "./ducks";
import * as api from "./api";

function* loginUser({ env, email }) {
  try {
    ls.set("env", env);
    // Call POST /users/login
    const { id, token } = yield call(api.login, email);
    ls.set("token", token);
    yield put(actions.login.success(id));
  } catch (err) {
    console.error(err);
    yield put(actions.login.failure(err.message));
  }
}

function* getUserData() {
  try {
    const user = yield call(api.getSelf);
    const { email, name } = user;
    yield put(actions.getUserData.success(name, email));
  } catch (err) {
    console.error(err);
  }
}

function* fetchUnassociatedTemplates() {
  try {
    const list = yield call(api.getUnassociatedTemplates);
    yield put(actions.fetchUnassociatedTemplates.success(list));
  } catch (err) {
    console.error(err);
    yield put(actions.fetchUnassociatedTemplates.failure(err.message));
  }
}

function* fetchTemplate({ id }) {
  try {
    const template = yield call(api.getTemplate, id);
    yield put(actions.fetchTemplate.success(template));
    const { generic_template_id = null } = template;
    yield put(actions.selectGenericTemplate(generic_template_id));
  } catch (err) {
    console.error(err);
    yield put(actions.fetchTemplate.failure(err.message));
  }
}

function* fetchTemplates({ nameFilter }) {
  try {
    const templates = yield call(api.getTemplates, nameFilter);
    yield put(actions.fetchTemplates.success(templates));
  } catch (err) {
    console.error(err);
    yield put(actions.fetchTemplates.failure(err.message));
  }
}

function* fetchGenericTemplates() {
  try {
    const templates = yield call(api.getGenericTemplates);
    const indexed = templates.reduce((res, cur) => ({ ...res, [cur.id]: cur }), {});
    yield put(actions.fetchGenerics.success(indexed));
  } catch (err) {
    console.error(err);
    yield put(actions.fetchGenerics.failure(err.message));
  }
}

function* associateTemplates({ templateId, genericTemplateId, mapping }) {
  try {
    yield call(api.associateTemplates, templateId, genericTemplateId, mapping);
    yield put(actions.associateTemplates.success());
  } catch (err) {
    console.error(err);
    yield put(actions.associateTemplates.failure(err.message));
  }
}

function* saveGenericTemplate({ genericTemplateId, payload, templateId}) {
  try {
    const genericTemplate = yield call(api.saveGenericTemplate, genericTemplateId, payload);
    yield put(actions.saveGenericTemplate.success(genericTemplate));

    // Refresh mappings
    const template = yield call(api.getTemplate, templateId);
    yield put(actions.fetchTemplate.success(template));
  } catch (err) {
    console.error(err);
    yield put(actions.saveGenericTemplate.failure(err.message));
  }
}

function* createGenericTemplate({ name }) {
  try {
    const template = yield call(api.createGenericTemplate, name);
    yield put(actions.createGenericTemplate.success(template));
    const { id } = template;
    yield put(actions.selectGenericTemplate(id));
  } catch (err) {
    console.error(err);
    yield put(actions.createGenericTemplate.failure(err.message));
  }
}

export default function* root() {
  yield all([
    takeLatest(types.LOGIN, loginUser),
    takeLatest(types.GET_USER_DATA, getUserData),

    takeLatest(types.FETCH_UNASSOCIATED_TEMPLATES, fetchUnassociatedTemplates),
    takeLatest(types.FETCH_TEMPLATE, fetchTemplate),
    takeLatest(types.FETCH_TEMPLATES, fetchTemplates),
    takeLatest(types.FETCH_GENERIC_TEMPLATES, fetchGenericTemplates),
    takeLatest(types.ASSOCIATE_TEMPLATES, associateTemplates),
    takeLatest(types.EDIT_GENERIC_TEMPLATE, saveGenericTemplate),
    takeLatest(types.CREATE_GENERIC_TEMPLATE, createGenericTemplate),
  ]);
}
