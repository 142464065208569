import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "grommet";
import { Redirect } from "react-router-dom";
import ls from "lscache";
import { actions } from "../ducks";
import { authClient } from "./index";

const SignoutButton = ({ signout }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const handleClick = () => {
    if (process.env.NODE_ENV === "development") {
      ls.remove("token");
      signout();
      setShouldRedirect(true);
    } else {
      authClient.logout({
        returnTo: "https://ml-labs.abstractcre.com",
      });
    }
  };

  if (shouldRedirect) {
    return <Redirect to={{ pathname: "/" }} />
  }

  return (
    <Button
      label="Log Out"
      onClick={handleClick}
      primary
    />
  );
};

SignoutButton.propTypes = {
  signout: PropTypes.func.isRequired,
};

const actionsToMap = { signout: actions.signout };

export default connect(null, actionsToMap)(SignoutButton);
