import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware, { END } from "redux-saga";
import ls from "lscache";

import baseReducer, { actions, types } from "./ducks";
import { reducer as alertsReducer } from "./alerts";
import saga from "./sagas";

const reducer = (state, action) => {
  if (action.type === types.SIGNOUT_SUCCESS) {
    state = undefined;
  }

  const alerts = alertsReducer(state ? state.alerts : [], action);
  return {
    ...baseReducer(state, action),
    alerts,
  };
};

function configureStore(sagaMiddleware) {
  const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore(sagaMiddleware);
sagaMiddleware.run(saga);

const loginInfo = ls.get("login");
if (loginInfo !== null) {
  store.dispatch(actions.login.success(loginInfo.id));
}
