import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ls from "lscache";
import { actions } from "../ducks";
import { authClient } from "./index";
import { Route, Redirect } from "react-router-dom";
import SignoutButton from "./SignoutButton";
import TemplatesDashboard from "../template-mappings/TemplatesDashboard";
import TemplateMappingView from "../template-mappings/TemplateMappingView";
import "./styles/Authenticated.css";

const content = (email, env) => (
  <div className="Authenticated">
    <nav className="Navbar">
      <span>{email}</span>
      <span>Env: {env}</span>
      <SignoutButton />
    </nav>

    <Route exact path="/" component={TemplatesDashboard} />
    <Route path="/template/:id" component={TemplateMappingView} />
  </div>
);

/* DEV ENVIRONMENT */
const AuthenticatedDev = ({ email, getUserData, isGettingUserData }) => {
  const hasToken = !!ls.get("token");
  const env = ls.get("env");

  useEffect(() => {
    if (getUserData && hasToken) getUserData();
  }, [getUserData, hasToken]);

  return hasToken ? (
    content(email, env)
  ) : (
    <Redirect to={{pathname: "/login"}} />
  );
};

AuthenticatedDev.propTypes = {
  email: PropTypes.string.isRequired,
  getUserData: PropTypes.func.isRequired,
  isGettingUserData: PropTypes.bool.isRequired,
};

/* STAGING + PROD */
const AuthenticatedDeployed = ({
  email,
  getUserData,
  isGettingUserData,
}) => {
  const [user, setUser] = useState(undefined);

  const env = ls.get("env");
  const isLoggedIn = typeof user === "object" && user !== null;
  const isNotLoggedIn = user === null;

  useEffect(() => {
    (async () => {
      const user = await authClient.getUser();
      setUser(user || null);
    })();
  }, [setUser]);

  useEffect(() => {
    if (isNotLoggedIn) {
      ls.set("env", "prod");
      authClient.loginWithRedirect();
    }
  }, [isNotLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && getUserData) getUserData();
  }, [getUserData, isLoggedIn]);

  return content(email, env);
};

AuthenticatedDeployed.propTypes = {
  email: PropTypes.string.isRequired,
  getUserData: PropTypes.func.isRequired,
  isGettingUserData: PropTypes.bool.isRequired,
};

const Authenticated =
  process.env.NODE_ENV === "development"
    ? AuthenticatedDev
    : AuthenticatedDeployed;

const mapStateToProps = ({ email }) => ({ email });

const actionsToMap = {
  getUserData: actions.getUserData.request,
};

export default connect(mapStateToProps, actionsToMap)(Authenticated);
