import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactLoading from "react-loading";

import { actions } from "../ducks";
import TemplateMappingPanel from "./TemplateMappingPanel";
import GenericTemplateFieldsList from "./GenericTemplateFieldsList";
import CreateGenericTemplate from "./CreateGenericTemplate";
import "./styles/TemplateMappingView.css";

class TemplateMappingView extends Component {
  componentDidMount() {
    const {
      fetchTemplate,
      fetchGenericTemplates,
      match: { params: { id } },
    } = this.props;
    if (id) {
      fetchTemplate(id);
      fetchGenericTemplates();
    }
  }

  render() {
    const { isFetching, curGenericTemplate } = this.props;

    return (
      <div className="TemplateMappingView">
        { isFetching ?
          <ReactLoading
            className="loader"
            type="spin"
            color="purple"
            height="5%"
            width="5%"
          /> :
          <Fragment>
            <TemplateMappingPanel />
            {curGenericTemplate === "new" ?
              <CreateGenericTemplate /> :
              <GenericTemplateFieldsList />
            }
          </Fragment>
        }
      </div>
    );
  }
}

TemplateMappingView.propTypes = {
  isFetching: PropTypes.bool.isRequired,

  fetchTemplate: PropTypes.func.isRequired,
  fetchGenericTemplates: PropTypes.func.isRequired,
  curGenericTemplate: PropTypes.any,
};

const mapStateToProps = ({
  isFetchingTemplate,
  isFetchingGenericTemplates,
  curGenericTemplate,
}) => ({
  isFetching: isFetchingTemplate || isFetchingGenericTemplates,
  curGenericTemplate,
});

const actionsToMap = {
  fetchTemplate: actions.fetchTemplate.request,
  fetchGenericTemplates: actions.fetchGenerics.request,
};

export default connect(mapStateToProps, actionsToMap)(TemplateMappingView);
