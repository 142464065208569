import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextInput, Button } from "grommet";
import { Redirect } from "react-router-dom";
import Select from "react-select";

import { actions } from "../ducks";

import "./styles/LoginView.css";

const isDev = process.env.NODE_ENV === 'development';
const envOptions = isDev ?
  [{ value: 'dev', label: 'Dev' }] :
  [{ value: 'prod', label: "Production" }, { value: 'staging', label: 'Staging' }];

const LoginView = ({ isLoggedIn, login }) => {
  const [email, setEmail] = useState("");
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (!isDev || isLoggedIn) setShouldRedirect(true);
  }, [isLoggedIn]);

  const [env, setEnv] = useState(envOptions[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(env.value, email);
  }

  return (
    <div className="LoginView">
      {shouldRedirect ?
        <Redirect to={{pathname: "/"}} /> :
        <form onSubmit={handleSubmit}>
          <TextInput
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>ENV:</label>
          <Select
            options={envOptions}
            value={env}
            onChange={v => setEnv(v)}
          />
          <Button
            label="Enter"
            type="submit"
            primary
          />
        </form>
      }
    </div>
  );
};

LoginView.propTypes = {
  login: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ isLoggedIn }) => ({ isLoggedIn });

const actionsToMap = {
  login: actions.login.request,
};

export default connect(mapStateToProps, actionsToMap)(LoginView);
