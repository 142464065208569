import { Auth0Client } from "@auth0/auth0-spa-js";

let audience = "";

if (process.env.NODE_ENV === "development") {
  audience = "http://localhost:5000";
} else {
  audience = "https://api.abstractcre.com";
}

const clientId = "Fg0V2edAEtWQAFbjPpl7jMDDne1rp3gk";
const isDevEnv = process.env.NODE_ENV === "development";
const redirectUri = `https://ml-labs.abstractcre.com/callback`;

export const authClient = isDevEnv
  ? null
  : new Auth0Client({
      audience,
      client_id: clientId,
      domain: "auth.abstractcre.com",
      redirect_uri: redirectUri,
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    });
